var swiper = new Swiper('.swiper-container', {
  speed: 600,
  loop: true,
  autoplay: 4000,
  effect: "fade",
  nextButton: '.swiper-button-next',
  prevButton: '.swiper-button-prev',
  pagination: '.swiper-pagination',
  paginationClickable: true,
  // effect: 'coverflow',
  // grabCursor: true,
  // centeredSlides: true,
  // slidesPerView: 2,
  // coverflow: {
  //   rotate: 50,
  //   stretch: 0,
  //   depth: 100,
  //   modifier: 1,
  //   slideShadows : true
  // },
  // breakpoints: {
  //   767: {
  //     slidesPerView: 1,
  //     spaceBetween: 0
  //   }
  // }
});
